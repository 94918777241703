/* General Layout */
.luxury-cart-container {
  background-color: #f7f7f7; /* Dark theme */
  color: #f5f5f5; /* Light text */
  padding: 20px;
  font-family: 'Roboto', sans-serif; /* Premium font */
}

.luxury-cart-header {
  text-align: center;
  margin-bottom: 40px;
}

.luxury-cart-title {
  font-size: 2.5rem;
  color: #812A6E; /* Gold accent */
}

.luxury-cart-subtitle {
  font-size: 1.2rem;
  color: #7f316e;
}

.luxury-cart-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.luxury-cart-items {
  flex: 0 0 60%;
  margin-right: 20px;
}

.luxury-cart-summary-card {
  flex: 0 0 35%;
  background-color: #292929;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Cart Items */
.luxury-cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e2e2e2;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.luxury-item-details {
  flex: 1;
}

.luxury-item-name {
  font-size: 1.3rem;
  color: #812A6E;
  margin-bottom: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}


.luxury-item-price,
.luxury-item-total {
  font-size: 1.2rem;
  color: black;
}

.luxury-quantity-section {
  display: flex;
  align-items: center;
  color: #000;
}

.luxury-quantity-input-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #000;
}

.luxury-quantity-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 8px;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.luxury-quantity-button:hover {
  background-color: #555;
}

.luxury-quantity-input {
  width: 40px;
  text-align: center;
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px;
}

.luxury-remove-button {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.luxury-remove-button:hover {
  background-color: #ff7f7f;
}

/* Cart Summary */
.luxury-cart-summary {
  text-align: center;
}

.luxury-summary-title {
  font-size: 1.8rem;
  color: #812A6E;
  margin-bottom: 20px;
}

.luxury-total-price {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fff;
}

.luxury-checkout-button {
  background-color: #f5ba42;
  color: #000;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.luxury-checkout-button:hover {
  background-color: #ffdd80;
}

/* Modal */
.luxury-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.luxury-modal-content {
  background-color: #1a1a1a;
  color: #fff;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.luxury-modal-close-button {
  background-color: #f5ba42;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
}

.luxury-modal-close-button:hover {
  background-color: #ffdd80;
}

/* Empty Cart */
.luxury-empty-cart {
  text-align: center;
  font-size: 1.5rem;
  color: #bbb;
}
