/* src/pages/Checkout.css */
body {
  font-family: 'Poppins', sans-serif; /* Clean, modern font */
}

.checkout-container {
  background: linear-gradient(135deg, #f8f9fa, #e0e0e0); /* Soft gradient background */
  padding: 30px;
  max-width: 900px;
  margin: 40px auto;
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.checkout-title {
  font-size: 2.5em;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
}

.checkout-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.checkout-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-weight: 500;
  font-size: 1.1em;
  color: #34495e;
  margin-bottom: 8px;
  display: block;
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #bdc3c7;
  border-radius: 8px;
  font-size: 1em;
  background-color: #ecf0f1;
  outline: none;
  transition: border-color 0.2s ease;
}

.input-group input:focus,
.input-group textarea:focus {
  border-color: #3498db; /* Highlight on focus */
}

.summary-title {
  font-size: 1.8em;
  color: #2c3e50;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
}

.checkout-product-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.checkout-product-list li {
  font-size: 1.2em;
  padding: 10px;
  border-bottom: 1px solid #ecf0f1;
}

.total-price {
  font-size: 2em;
  font-weight: bold;
  text-align: right;
  margin-bottom: 20px;
}

.checkout-submit-button {
  background-color: #3498db;
  color: white;
  padding: 15px;
  font-size: 1.3em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.checkout-submit-button:hover {
  background-color: #2980b9;
}

.checkout-submit-button:active {
  transform: scale(0.98); /* Button press effect */
}
