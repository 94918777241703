.product-type-slider-container {
    position: relative;
    width: 100%;
    overflow: hidden; /* Hide overflow */
    margin: 0 auto;
}

.slider-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem; /* Responsive heading size */
}

.slider-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.product-type-slider {
    display: flex;
    gap: 20px; /* Space between items */
    padding: 10px; /* Adjusted padding */
    white-space: nowrap; /* Prevent line breaks */
    box-sizing: border-box;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-behavior: smooth; /* Smooth scrolling */
}

/* Ensure proper centering for all cards */
.product-type-card {
    min-width: 100px; /* Ensure the card has a minimum width */
    flex: 0 0 auto; /* Prevent card shrinking */
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
    transition: transform 0.2s; /* Smooth scaling effect on hover */
    border: 1px solid #ddd; /* Card border */
    border-radius: 10px; /* Rounded corners */
    padding: 10px; /* Padding inside the card */
    background-color: #fff; /* Card background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Card shadow */
}

.product-type-card:hover {
    transform: scale(1.05); /* Scale up the card slightly on hover */
}

/* Arrows for navigation */
.slider-arrow {
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.left-arrow {
    left: 10px; /* Adjust left arrow position */
}

.right-arrow {
    right: 10px; /* Adjust right arrow position */
}

/* Hide default scrollbar */
.product-type-slider::-webkit-scrollbar {
    display: none;
}

/* Card content styles */
.product-type-card p {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-top: 8px; /* Adjusted margin for better spacing */
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .product-type-slider {
        gap: 10px; /* Reduce gap between items on mobile */
        padding: 5px; /* Adjusted padding for mobile */
    }

    .product-type-card {
        min-width: 120px; /* Reduce card width for smaller screens */
        padding: 8px; /* Adjust padding for mobile */
    }

    .slider-heading {
        font-size: 1.3rem; /* Smaller heading size for mobile */
    }

    .slider-arrow {
        font-size: 1.5rem; /* Smaller arrow size for mobile */
        padding: 5px; /* Smaller padding for mobile */
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .product-type-slider {
        gap: 15px; /* Adjust gap between items for tablets */
        padding: 10px; /* Adjusted padding for tablets */
    }

    .product-type-card {
        min-width: 140px; /* Slightly larger cards for tablets */
        padding: 10px; /* Adjust padding for tablets */
    }

    .slider-arrow {
        font-size: 1.8rem; /* Slightly smaller arrow size for tablets */
        padding: 8px; /* Adjust padding for tablets */
    }
}
