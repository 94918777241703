/* Banner Section */
.banner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #333; /* Add a background color if there's no background image */
  padding: 0 20px; /* Padding to prevent text from hitting screen edges on mobile */
}

.banner-content {
  color: #fff;
  max-width: 80%; /* Limits text width for readability */
  margin: auto;
}

.banner-title {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.banner-subtitle {
  font-size: 1.5rem;
  margin-top: 10px;
}

.banner-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.banner-button:hover {
  background-color: #555;
}

/* Products Section */
.products-section {
  padding: 50px;
  background-color: #f5f5f5;
}

.products-section h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .banner {
    height: 70vh; /* Reduce height for smaller screens */
    padding: 20px;
  }

  .banner-title {
    font-size: 2.5rem; /* Slightly smaller font on tablets */
  }

  .banner-subtitle {
    font-size: 1.2rem;
  }

  .banner-button {
    padding: 8px 15px; /* Smaller padding on mobile */
  }

  .products-section {
    padding: 30px;
  }

  .products-section h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .banner {
    height: 60vh; /* Smaller banner on mobile for better fit */
    padding: 20px;
  }

  .banner-title {
    font-size: 2rem; /* Reduce size for smaller screens */
  }

  .banner-subtitle {
    font-size: 1rem;
  }

  .banner-button {
    padding: 8px 12px; /* Even smaller padding on mobile */
  }

  .products-section {
    padding: 20px;
  }

  .products-section h2 {
    font-size: 1.5rem; /* Smaller title size for mobile */
  }
}
