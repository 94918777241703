/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif; /* Modern font */
}

/* App Container */
.app-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 2px solid #e0e0e0;
}

.header .logo {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.header .search-bar {
  display: flex;
  align-items: center;
  width: 60%;
}

.search-bar input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 5px 0 0 5px;
  outline: none;
  font-size: 16px;
}

.search-bar button {
  padding: 10px;
  border: 2px solid #ddd;
  background-color: #333;
  color: white;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  transition: background-color 0.3s ease;
}

.search-bar button:hover {
  background-color: #555;
}

.header .nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-links a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.nav-links .cart-icon {
  font-size: 24px;
  cursor: pointer;
}

/* Responsive Header Adjustments */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  .header .logo {
    margin-bottom: 10px;
  }

  .header .search-bar {
    width: 100%;
    margin-bottom: 10px;
  }

  .header .nav-links {
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Banner Styles */
.banner {
  position: relative;
  width: 100%;
  height: 400px; /* Default height for larger screens */
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0.3)), 
              url('./components/world-saree-day-copy-space-background_548646-56572.avif') center center/cover no-repeat;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Mobile Responsiveness for the Banner */
@media (max-width: 768px) {
  .banner {
    height: 400px; /* Reduce height for tablet screens */
    background-position: center; /* Keep background centered */
  }
}

@media (max-width: 480px) {
  .banner {
    height: 250px; /* Further reduce height for mobile */
    background-position: top; /* Change position to top to focus on important content */
  }
}

/* Product Type Slider */
.product-type-slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.slider-heading {
  text-align: center;
  margin-bottom: 20px;
}

.slider-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.product-type-slider {
  display: flex;
  gap: 20px;
  padding: 10px;
  white-space: nowrap;
  box-sizing: border-box;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.product-type-card {
  width: 300px;
  height: 400px;
  flex: 0 0 auto;
  text-align: center;
  cursor: pointer;
  margin: 20px;
  transition: transform 0.2s;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-type-card:hover {
  transform: scale(1.05);
}

.product-type-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .product-type-slider {
        gap: 10px; /* Reduce gap between items on mobile */
        padding: 5px; /* Adjusted padding for mobile */
    }

    .product-type-card {
        min-width: 120px; /* Reduce card width for smaller screens */
        padding: 8px; /* Adjust padding for mobile */
    }

    .slider-heading {
        font-size: 1.3rem; /* Smaller heading size for mobile */
    }

    .slider-arrow {
        font-size: 1.5rem; /* Smaller arrow size for mobile */
        padding: 5px; /* Smaller padding for mobile */
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .product-type-slider {
        gap: 15px; /* Adjust gap between items for tablets */
        padding: 10px; /* Adjusted padding for tablets */
    }

    .product-type-card {
        min-width: 140px; /* Slightly larger cards for tablets */
        padding: 10px; /* Adjust padding for tablets */
    }

    .slider-arrow {
        font-size: 1.8rem; /* Slightly smaller arrow size for tablets */
        padding: 8px; /* Adjust padding for tablets */
    }
}


/* Products Section */
.products-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

/* Adjusting Product Card Width for Different Resolutions */
.product-card {
  border: 2px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f9f9f9;
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.product-info {
  padding: 10px;
}

.product-info h4 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.product-info p {
  font-size: 14px;
  color: #666;
}

.product-info .price {
  font-weight: bold;
  color: #d9534f;
}

/* Footer */
.footer {
  text-align: center;
  padding: 20px;
  margin-top: 40px;
  border-top: 2px solid #e0e0e0;
}

.footer p {
  color: #666;
  font-size: 14px;
}

/* Media Query for 1024px Resolution - 4 Product Cards per Row */
@media (min-width: 1024px) {
  .product-card {
    width: calc(25% - 20px); /* Four products per row */
  }
}

/* Media Query for 768px Resolution - 3 Product Cards per Row */
@media (min-width: 768px) and (max-width: 1023px) {
  .product-card {
    width: calc(33.33% - 20px); /* Three products per row */
  }
}

/* Media Query for 480px Resolution - 2 Product Cards per Row */
@media (max-width: 767px) {
  .product-card {
    width: calc(50% - 20px); /* Two products per row */
  }
}

/* Media Query for Small Devices (up to 480px) - 1 Product Card per Row */
@media (max-width: 480px) {
  .product-card {
    width: 100%; /* Full width for each card */
  }
}
