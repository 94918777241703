.contact-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f4f4f8;
  border-radius: 15px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Playfair Display', serif;
}

.contact-title {
  font-size: 3rem;
  color: #2f2e41;
  text-align: center;
  margin-bottom: 30px;
}

.contact-description {
  text-align: center;
  font-size: 1.3rem;
  color: #6b6b83;
  margin-bottom: 40px;
  line-height: 1.6;
}

.contact-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contact-card {
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.contact-card h3 {
  font-size: 1.8rem;
  color: #2f2e41;
  margin-bottom: 15px;
}

.contact-card p {
  font-size: 1.1rem;
  color: #6b6b83;
  margin: 10px 0;
}

.contact-card a {
  color: #a88c5d;
  text-decoration: none;
  font-weight: bold;
}

.contact-card a:hover {
  text-decoration: underline;
}

.contact-form-container {
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-form-container h3 {
  font-size: 1.8rem;
  color: #2f2e41;
  margin-bottom: 20px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-field, .message-field {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
}

.message-field {
  height: 150px;
  resize: none;
}

.submit-button {
  padding: 15px;
  background-color: #a88c5d;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #8b7049;
}
