/* src/pages/HomePage.css */

/* General container styling for homepage */
.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Use full width */
  min-height: 100vh;
  background-color: #f5f5f5; /* Light background */
  padding: 0; /* Ensure no extra margins */
}

/* Navbar */
.homepage-header {
  width: 100%; /* Full width */
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Main content */
.homepage-main {
  width: 100%; /* Full width */
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between sections */
}

/* Banner Section */
.homepage-banner {
  width: 100%; /* Full width banner */
  height: auto;
  margin-bottom: 0; /* No overlap */
  object-fit: cover; /* Ensure banner image fits */
}

/* Products Section */
.homepage-products {
  width: 100%; /* Full width for desktop */
  margin: 0; /* Remove negative margins */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
}

/* Product grid */
.homepage-products-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Spacing between product cards */
  max-width: 1200px; /* Max width to prevent overflow */
}

/* Individual product card */
.product-card {
  width: calc(25% - 20px); /* 4 products per row */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: scale(1.05);
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .product-card {
    width: calc(33.33% - 20px); /* 3 products per row at 768px */
  }
}

@media (max-width: 768px) {
  .product-card {
    width: calc(50% - 20px); /* 2 products per row at 480px */
  }
}

@media (max-width: 480px) {
  .product-card {
    width: calc(100% - 20px); /* Full width on extra small screens */
  }
}
