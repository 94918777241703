/* General Navbar Styling */
.navbar {
  background: white;
  color: #fff;
  padding: 15px 20px;
  position: relative;
  z-index: 1000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  flex-wrap: wrap;
}

.menu-icon {
  cursor: pointer;
  color: #e51d9c;
  font-size: 1.8rem;
}

.logo-container .navbar-logo-image {
  width: 120px;
  height: auto;
  transition: transform 0.3s;
}

.logo-container:hover .navbar-logo-image {
  transform: scale(1.1);
}

.collection-text {
  font-size: 1.5rem;
  color: #e51d9c;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0 auto;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-link {
  color: #e51d9c;
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s, transform 0.3s;
}

.nav-link:hover {
  color: #e51d9c;
  transform: scale(1.1);
}

.cart-button, .contact-button {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Category Dropdown Menu */
.category-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #e51d9c;
  padding: 10px 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.category-menu ul {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  list-style: none;
}

.category-menu li {
  color: #e51d9c;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
}

.category-menu li:hover {
  background: white;
  color: #e51d9c;
}

.subcategory-menu {
  position: absolute;
  top: 0;
  left: 100%;
  background: white;
  padding: 10px;
  min-width: 200px;
  display: none;
}

.subcategory-menu li {
  padding: 8px 10px;
  color: #e51d9c;
  cursor: pointer;
}

.category-menu li:hover .subcategory-menu {
  display: block;
}

.subcategory-menu li:hover {
  color: #e51d9c;
  background-color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
    font-size: 1.5rem;
  }

  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .logo-container {
    display: none; /* Hide logo for mobile view */
  }

  .navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .collection-text {
    font-size: 1.2rem;
    flex-grow: 1;
    text-align: center;
    padding: 5px 0;
  }

  .navbar-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }

  .nav-link {
    flex: 1;
    text-align: center;
    font-size: 1rem;
  }

  .category-menu {
    width: 100%;
  }

  .category-menu ul {
    flex-direction: column;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .logo-container .navbar-logo-image {
    width: 100px;
  }

  .collection-text {
    font-size: 1.0rem;
  }

  .navbar-right {
    gap: 15px;
  }

  .nav-link {
    font-size: 1.1rem;
  }

  .menu-icon {
    font-size: 1.5rem;
  }
}
